// Variable overrides: https://getbootstrap.com/docs/4.0/getting-started/theming/

$gbo-light: #f58b00;

$primary: $gbo-light;

// Navbar
$navbar-brand-width: 220px;
$navbar-color: $gbo-light;
$navbar-hover-color:  darken($gbo-light, 10%);

// Sidebar
$sidebar-width: 220px;
$sidebar-bg: $gbo-light;//gray-100
$sidebar-minimized-width: 50px;

// Sidebar Navigation
$sidebar-nav-link-color: #fff;//gray-700
$sidebar-nav-link-icon-color: #fff;

$sidebar-nav-link-active-color: #fff;
$sidebar-nav-link-active-bg: lighten($gbo-light,10%);
$sidebar-nav-link-active-icon-color: #fff;

$sidebar-nav-link-hover-color: #fff;
$sidebar-nav-link-hover-bg: lighten($gbo-light,10%);
$sidebar-nav-link-hover-icon-color: #fff;

$sidebar-nav-link-disabled-color:       darken(#fff, 10%);
$sidebar-nav-link-disabled-bg:          $sidebar-bg;
$sidebar-nav-link-disabled-icon-color:  darken(#fff, 10%);

// Sidebar Header
$sidebar-header-bg: $gbo-light;

// Sidebar Minimizer
$sidebar-minimizer-indicator-color: #fff;

// Footer
$footer-bg: #fff;
$footer-color: #73818f;//gray-600

//Spinner
$spinkit-spinner-color: $gbo-light;
$spinkit-spinner-margin: auto;
$spinkit-size: 60px;


