// Here you can add other styles
$gbo-light: #f58b00;
$background: #e4e5e6;

@include bg-variant(".bg-background", #e4e5e6);

$breakpoint-mobile: md;

.navbar-brand { color: $gbo-light; }
.navbar { color: $gbo-light;}

.dropdown-header { 
    background-color: lighten($gbo-light, 10%);
    color: #fff;
}

.dropdown-item {
    color: #73818f;//gray-600
}

.dropdown-item:hover{
    background-color: lighten($gbo-light, 10%);
    color: #fff;
    i{
        color: #fff;
    }
}

.sidebar-minimized .sidebar .nav-link .badge {
    display: inline;
    margin-top: -10px;
    margin-right: -8px;
}

.sidebar-minimized .sidebar-show .sidebar .nav-link .badge {
    display: inline;
}

@include media-breakpoint-down($breakpoint-mobile) {
    .app-header.navbar{
        background-color: $gbo-light;
        color: #fff;
        .navbar-brand {
            color: #fff;
            width: fit-content
        }
        .navbar-nav {
            .nav-link {
              color: #fff;
              
              @include hover-focus {
                color: darken(#fff, 5%);
              }
            }
        }
        .navbar-toggler-icon {
            background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#fff' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
        }
    }
    
}

// Toast styles
@include media-breakpoint-up($breakpoint-mobile-down) {
    .Toastify__toast-container {
        position: relative;    
    }
}

.Toastify__toast-container--top-center {
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.Toastify__toast-container.scrollbarVisible{
    position: fixed;    
}

.Toastify__toast--success {
    background-color: #4dbd74;
}

.Toastify__toast--error {
    background-color: #f86c6b;
}

.Toastify__toast--info {
    background-color: #63c2de;
}

.Toastify__toast--warning {
    background-color: #ffc107;
}

// DateRangePicker-Styles
.DateRangePicker_picker {
    z-index: 10;
}

.CalendarDay__selected_span {
    background: lighten($gbo-light,15%); //background
    color: white; //text
    border: 1px solid $gbo-light; //default styles include a border
  }

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: $gbo-light;
    border: 1px double $gbo-light;
    color: #fff
  }

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: darken($gbo-light,10%);
    color: white;
    border: 1px solid darken($gbo-light,10%);
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: lighten($gbo-light,25%);
  border: 1px double lighten($gbo-light,15%);
  color: darken($gbo-light,25%),
}
.CalendarDay__hovered_span:active {
  background: lighten($gbo-light,15%);
  border: 1px double lighten($gbo-light,15%);
  color: darken($gbo-light,25%);
}

.DateInput_input__focused {
    border-bottom: 2px solid $gbo-light;
}

.DateInput_input {
    color: #5c6873;
}

.invalid .SingleDatePicker .SingleDatePickerInput{
    border: 1px solid #f86c6b;
}

.invalid div.invalidMessage {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f86c6b;
}

.SingleDatePicker {
    .SingleDatePickerInput_calendarIcon {
        padding: 5px;
        margin: 0px 8px 0 5px;
        position: absolute;
        right: 0;
    }

    .SingleDatePickerInput_clearDate {
        padding: 10px;
        margin: 0 35px 0 5px;
        position: absolute;
        right: 0;
        top: 50%;   
    }
}


.DateRangePicker {
    .DateRangePickerInput_calendarIcon {
        padding: 5px;
        margin: 2px 8px 0 5px;
        position: absolute;
        right: 0;
        
    }

    .DateRangePickerInput_clearDates {
        padding: 10px;
        margin: 0 35px 0 5px;
        position: absolute;
        right: 0;
        top: 50%;   
    }

    .DateInput__small {
        width: 85px;
    }
}

// React-Big-Calendar styles
.rbc-event {
    background-color: $gbo-light;
    color: #fff;
}
.rbc-event.rbc-selected {
    font-weight: bold;
}
.rbc-event:focus {
    outline: 5px auto $gbo-light; 
}
.rbc-day-slot .rbc-event {
    border: 1px solid darken($gbo-light,10%);
}

.rbc-today {
    background-color: lighten($gbo-light,40%); 
}

// react-calendar-timeline styles
.react-calendar-timeline .rct-header-root {
    background: $gbo-light;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: lighten($gbo-light,40%);
    box-sizing: border-box;
    border-bottom: 1px solid #bbb;
}